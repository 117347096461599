import { Paper } from "@mui/material";
import "./UserList.css";
import { DataGrid } from "@mui/x-data-grid";
import { Email } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { rows } from "../../Data";
import { useState } from "react";
import { Link } from "react-router-dom";
export default function UserList() {
  const [data, setdata] = useState(rows);

  function handleDelete(id) {
    setdata(data.filter((item) => item.id !== id));
  }

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "First name", width: 130 },
    { field: "lastName", headerName: "Last name", width: 130 },
    {
      field: "avatar",
      headerName: "Avatar",
      width: 130,
      renderCell: (params) => {
        return (
          <div>
            <img style={{ marginTop: "10px" }} src={params.row.avatar} alt="" />
          </div>
        );
      },
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 90,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 120,
      valueGetter: (value, row) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      width: 120,
    },

    {
      field: "status",
      headerName: "Status",
      type: "string",
      width: 90,
    },
    {
      field: "transaction",
      headerName: "Transaction",
      type: "number",
      width: 90,
    },
    {
      field: "action",
      headerName: "Action",
      width: "150",
      renderCell: (params) => {
        return (
          <div className="action">
            <Link className="Edit" to={"/users/" + params.row.id}>
              <EditIcon className="edit" />
            </Link>
            <DeleteIcon
              className="delete"
              onClick={() => handleDelete(params.row.id)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="userList">
      <Paper sx={{ height: 700, width: "100%" }}>
        <DataGrid
          rows={data}
          disableRowSelectionOnClick
          columns={columns}
          pageSizeOptions={[8, 10]}
          checkboxSelection
          sx={{ border: 0 }}
        />
      </Paper>
    </div>
  );
}
