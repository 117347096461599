import { type } from "@testing-library/user-event/dist/type";
import "./WidegtsLg.css";

export default function WidgetsLg() {
  const Button = ({ type }) => {
    return <button className={"widegtLgBtn " + type}>{type}</button>;
  };
  return (
    <div className="widegtLg">
      <h3 className="widegtTitle">Latest transactions</h3>
      <table className="widegtTable">
        <tr className="widegtTr">
          <th className="widegtTh">Customer</th>
          <th className="widegtTh">Date</th>
          <th className="widegtTh">Amount</th>
          <th className="widegtTh">Status</th>
        </tr>
        <tr className="widegtTr">
          <td className="widegtTdUser">
            <img src="./4.jpg" alt="" />
            <span className="widegtName">Susan</span>
          </td>

          <td className="widegtDate">2 Jan</td>
          <td className="widegtAmount">$256 </td>
          <td className="widegtStaus">
            <Button type="Approved" />
          </td>
        </tr>
        <tr className="widegtTr">
          <td className="widegtTdUser">
            <img src="./4.jpg" alt="" />
            <span className="widegtName">Susan</span>
          </td>

          <td className="widegtDate">2 Jan</td>
          <td className="widegtAmount">$256 </td>
          <td className="widegtStaus">
            <Button type="Approved" />
          </td>
        </tr>
        <tr className="widegtTr">
          <td className="widegtTdUser">
            <img src="./4.jpg" alt="" />
            <span className="widegtName">Susan</span>
          </td>

          <td className="widegtDate">2 Jan</td>
          <td className="widegtAmount">$256 </td>
          <td className="widegtStaus">
            <Button type="Pending" />
          </td>
        </tr>
        <tr className="widegtTr">
          <td className="widegtTdUser">
            <img src="./4.jpg" alt="" />
            <span className="widegtName">Susan</span>
          </td>

          <td className="widegtDate">2 Jan</td>
          <td className="widegtAmount">$256 </td>
          <td className="widegtStaus">
            <Button type="Approved" />
          </td>
        </tr>
        <tr className="widegtTr">
          <td className="widegtTdUser">
            <img src="./4.jpg" alt="" />
            <span className="widegtName">Susan</span>
          </td>

          <td className="widegtDate">2 Jan</td>
          <td className="widegtAmount">$256 </td>
          <td className="widegtStaus">
            <Button type="Declined" />
          </td>
        </tr>
      </table>
    </div>
  );
}
