import "./features.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
export default function Features() {
  return (
    <div className="featured">
      <div className="featureditem">
        <span className="featuretitle">Revenue</span>
        <div className="featuredmony">
          <sapn className="money">$2.157</sapn>
          <sapn className="moneyrate">
            -11.4 <ArrowDownwardIcon className="featureicon negative" />
          </sapn>
        </div>
        <span className="featuresub">Compared to last month</span>
      </div>
      <div className="featureditem">
        <span className="featuretitle">Sales</span>
        <div className="featuredmony">
          <sapn className="money">$4.157</sapn>
          <sapn className="moneyrate">
            -1.2 <ArrowDownwardIcon className="featureicon negative" />
          </sapn>
        </div>
        <span className="featuresub">Compared to last month</span>
      </div>
      <div className="featureditem">
        <span className="featuretitle">Cost</span>
        <div className="featuredmony">
          <sapn className="money">$2.227</sapn>
          <sapn className="moneyrate">
            1.5 <ArrowUpwardIcon className="featureicon" />
          </sapn>
        </div>
        <span className="featuresub">Compared to last month</span>
      </div>
    </div>
  );
}
