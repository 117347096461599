import "./sidebar.css";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import TimelineIcon from "@mui/icons-material/Timeline";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BarChartIcon from "@mui/icons-material/BarChart";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import WorkOutline from "@mui/icons-material/WorkOutlineOutlined";
import ReportIcon from "@mui/icons-material/Report";
import { Link } from "react-router-dom";

export default function SideBar() {
  return (
    <div className="sidebar">
      <div className="sidebar">
        <div className="sidebarwrapper">
          <div className="sidebarmenu">
            <h3 className="Dashboard"> Dashboard</h3>
            <ul className="sidebarlist">
              <Link to="/" className="links">
                <li className="active">
                  <LineStyleIcon className="sidebaricon" />
                  Home
                </li>
              </Link>
              <li>
                <TimelineIcon className="sidebaricon" />
                Analytics
              </li>
              <li>
                <TrendingUpIcon className="sidebaricon" />
                Sales
              </li>
            </ul>
          </div>
          <div className="sidebarmenu">
            <h3 className="Dashboard"> Quick Menu</h3>
            <ul className="sidebarlist">
              <Link className="links" to="/users">
                <li>
                  <PermIdentityIcon className="sidebaricon" />
                  Users
                </li>
              </Link>

              <Link to="/products" className="links">
                <li>
                  <StorefrontIcon className="sidebaricon" />
                  Products
                </li>
              </Link>
              <li>
                <AttachMoneyIcon className="sidebaricon" />
                Transaction
              </li>
              <li>
                <BarChartIcon className="sidebaricon" />
                Reports
              </li>
            </ul>
          </div>
          <div className="sidebarmenu">
            <h3 className="Dashboard"> Notifications</h3>
            <ul className="sidebarlist">
              <Link to="/noifactions" className="links">
                <li>
                  <MailOutlineIcon className="sidebaricon" />
                  Mail
                </li>
              </Link>
              <li>
                <DynamicFeedIcon className="sidebaricon" />
                Feedback
              </li>
              <li>
                <ChatBubbleOutlineIcon className="sidebaricon" />
                Messages
              </li>
            </ul>
          </div>
          <div className="sidebarmenu">
            <h3 className="Dashboard"> Staff</h3>
            <ul className="sidebarlist">
              <li>
                <WorkOutline className="sidebaricon" />
                Manage
              </li>
              <li>
                <TrendingUpIcon className="sidebaricon" />
                Analytics
              </li>
              <li>
                <ReportIcon className="sidebaricon" />
                Reports
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
