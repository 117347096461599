import Chart from "../../components/chart/Chart";
import Features from "../../components/features/Features";
import "./Home.css";
import { Userdata } from "../../Data";
import WidgetsSm from "../../components/widgetsSm/WidgetsSm";
import WidgetsLg from "../../components/widgetsLg/WidgetsLg";

export default function Home() {
  return (
    <div className="home">
      <Features />
      <Chart
        data={Userdata}
        title="User Analytics"
        grid
        datakey="Active User"
      />
      <div className="homeWidgets">
        <WidgetsSm />
        <WidgetsLg />
      </div>
    </div>
  );
}
