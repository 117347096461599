import {
  CalendarToday,
  LocationSearching,
  MailLockOutlined,
  PhoneAndroid,
  Publish,
} from "@mui/icons-material";
import "./Update.css";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Link } from "react-router-dom";

export default function Update() {
  return (
    <div className="update">
      <div className="userTitleContainer">
        <h1> Edit User</h1>
        <Link to="/newUser">
          <button className="userBtn">Create</button>
        </Link>
      </div>
      <div className="userContainer">
        <div className="userShow">
          <div className="userShowTop">
            <img classNaTme="userImg" src="/2.jpg" alt="" />
            <div className="userShowTopTitle">
              <span className="userShowUserName">Jon</span>
              <span className="userShowUserTitle">Software Engeenir</span>
            </div>
          </div>
          <div className="userShowBooton">
            <span className="userShowTitle">Account Details</span>
            <div className="userShowInfo">
              <PermIdentityIcon className="userShowIcon" />
              <span className="userShowInfoTitle">jon54</span>
            </div>
            <div className="userShowInfo">
              <CalendarToday className="userShowIcon" />
              <span className="userShowInfoTitle">13/4/1996</span>
            </div>
            <span className="userShowTitle">Contact Details</span>

            <div className="userShowInfo">
              <PhoneAndroid className="userShowIcon" />
              <span className="userShowInfoTitle">+2 53456 131</span>
            </div>
            <div className="userShowInfo">
              <MailLockOutlined className="userShowIcon" />
              <span className="userShowInfoTitle">jon54@email.com</span>
            </div>
            <div className="userShowInfo">
              <LocationSearching className="userShowIcon" />
              <span className="userShowInfoTitle">Jordan-Amman</span>
            </div>
          </div>
        </div>
        <div className="userUpdate">
          <h2 className="userUpdateTitle">Edit</h2>
          <form className="userUpdateform">
            <div className="userUpdateLeft">
              <div className="userUpdateItem">
                <label>User Name</label>
                <input
                  type="text"
                  placeholder="jon54"
                  className="userUpdateInput"
                />
              </div>

              <div className="userUpdateItem">
                <label>Full Name</label>
                <input
                  type="text"
                  placeholder="Jon"
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label> Email</label>
                <input
                  type="text"
                  placeholder="jon54@email.com"
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Phone</label>
                <input
                  type="text"
                  placeholder="+2 53456 131"
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Address</label>
                <input
                  type="text"
                  placeholder="Jordan-Amman"
                  className="userUpdateInput"
                />
              </div>
            </div>
            <div className="userUpdateRight">
              <div className="userUpdateUpload">
                <img className="userUpdateImg" src="/2.jpg" alt="" />
                <label htmlFor="file">
                  <Publish style={{ cursor: "pointer" }} />
                </label>
                <input style={{ display: "none" }} type="file" id="file" />
              </div>
              <button className="userUpdateBtn">Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
