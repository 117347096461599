import React, { useState } from "react";

import "./notifaction.css";

const FeedbackForm = () => {
  // State to store the input values
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [feedback, setFeedback] = useState("");

  // State to store the success or error message
  const [responseMessage, setResponseMessage] = useState("");

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Simple validation
    if (!email || !message || !feedback) {
      setResponseMessage("Please fill out all fields.");
      return;
    }

    // Here you would typically send the form data to your backend server
    // For demonstration, let's just log it and reset the form
    console.log("Email:", email);
    console.log("Message:", message);
    console.log("Feedback:", feedback);

    setResponseMessage("Thank you for your feedback!");
    setEmail("");
    setMessage("");
    setFeedback("");
  };

  return (
    <div className="feedback-form">
      <h2>FeedBack</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="feedback">Feedback:</label>
          <textarea
            id="feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
      {responseMessage && <p>{responseMessage}</p>}
    </div>
  );
};

export default FeedbackForm;
