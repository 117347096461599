import "./NewUser.css";

export default function NewUser() {
  return (
    <div className="newUser">
      <h1 className="newUserTilte">New User</h1>
      <form className="newUserForm">
        <div className="newUseerItem">
          <label htmlFor="">User Name</label>
          <input type="text" placeholder="john" />
        </div>
        <div className="newUseerItem">
          <label htmlFor="">Full Name</label>
          <input type="text" placeholder="john smith" />
        </div>
        <div className="newUseerItem">
          <label htmlFor="">Email</label>
          <input type="email" placeholder="john@gmail.com" />
        </div>
        <div className="newUseerItem">
          <label htmlFor="">Passowrd</label>
          <input type="password" placeholder="Password" />
        </div>
        <div className="newUseerItem">
          <label htmlFor="">Address</label>
          <input type="text" placeholder="Jordan-Amman" />
        </div>
        <div className="newUseerItem">
          <label htmlFor="">Gender </label>
          <div className="newUserGenger">
            <input type="radio" name="gender" id="male" value="male" />
            <label For="mlae">Male</label>
            <input type="radio" name="gender" id="female" value="female" />
            <label For="female">Female</label>
          </div>
        </div>

        <div className="newUseerItem">
          <label>Active</label>
          <select className="newUserSelect" name="active" id="active">
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <button className="newUserBtn">Create</button>
      </form>
    </div>
  );
}
