import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { products } from "../../Data";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { render } from "@testing-library/react";

export default function Products() {
  const [data, setdata] = useState(products);
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "product",
      headerName: "Products",
      width: 130,
      renderCell: (params) => {
        return <div>{params.row.name}</div>;
      },
    },
    {
      field: "productImg",
      headerName: "product Image",
      width: 130,
      renderCell: (params) => {
        return (
          <div>
            <img
              style={{ marginTop: "10px", width: "50px" }}
              src={params.row.img}
              alt=""
            />
          </div>
        );
      },
    },

    {
      field: "stock",
      headerName: "Stock",
      type: "string",
      width: 120,
    },

    {
      field: "status",
      headerName: "Status",
      type: "string",
      width: 90,
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      width: 90,
    },
    {
      field: "action",
      headerName: "Action",
      width: "150",
      renderCell: (params) => {
        return (
          <div className="action">
            <Link className="Edit" to={"/product/" + params.row.id}>
              <EditIcon className="edit" />
            </Link>
            <DeleteIcon
              className="delete"
              onClick={() => handleDelete(params.row.id)}
            />
          </div>
        );
      },
    },
  ];

  function handleDelete(id) {
    setdata(data.filter((item) => item.id !== id));
  }
  return (
    <div className="products">
      <DataGrid
        rows={data}
        disableRowSelectionOnClick
        columns={columns}
        pageSizeOptions={[8, 10]}
        checkboxSelection
        sx={{ border: 0 }}
      />
    </div>
  );
}
