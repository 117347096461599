import "./WidegtsSm.css";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function WidgetsSm() {
  return (
    <div className="widegtSm">
      <span className="widegtSmTilte">New Join Members</span>

      <ul className="widgetSmList">
        <li className="widegtSmListItem">
          <img src="./1.jpg" alt="" />
          <div className="widegtSmUser">
            <span className="widegtSmUserName">John Kalr</span>
            <span className="widegtSmUserName">Software Engineer</span>
          </div>
          <button className="btnSm">
            <VisibilityIcon className="widegtIcon" />
            Display
          </button>
        </li>
        <li className="widegtSmListItem">
          <img src="./1.jpg" alt="" />
          <div className="widegtSmUser">
            <span className="widegtSmUserName">John Kalr</span>
            <span className="widegtSmUserName">Software Engineer</span>
          </div>
          <button className="btnSm">
            <VisibilityIcon className="widegtIcon" />
            Display
          </button>
        </li>
        <li className="widegtSmListItem">
          <img src="./1.jpg" alt="" />
          <div className="widegtSmUser">
            <span className="widegtSmUserName">John Kalr</span>
            <span className="widegtSmUserName">Software Engineer</span>
          </div>
          <button className="btnSm">
            <VisibilityIcon className="widegtIcon" />
            Display
          </button>
        </li>
        <li className="widegtSmListItem">
          <img src="./1.jpg" alt="" />
          <div className="widegtSmUser">
            <span className="widegtSmUserName">John Kalr</span>
            <span className="widegtSmUserName">Software Engineer</span>
          </div>
          <button className="btnSm">
            <VisibilityIcon className="widegtIcon" />
            Display
          </button>
        </li>
        <li className="widegtSmListItem">
          <img src="./1.jpg" alt="" />
          <div className="widegtSmUser">
            <span className="widegtSmUserName">John Kalr</span>
            <span className="widegtSmUserName">Software Engineer</span>
          </div>
          <button className="btnSm">
            <VisibilityIcon className="widegtIcon" />
            Display
          </button>
        </li>
        <li className="widegtSmListItem">
          <img src="./1.jpg" alt="" />
          <div className="widegtSmUser">
            <span className="widegtSmUserName">John Kalr</span>
            <span className="widegtSmUserName">Software Engineer</span>
          </div>
          <button className="btnSm">
            <VisibilityIcon className="widegtIcon" />
            Display
          </button>
        </li>
      </ul>
    </div>
  );
}
