 export const Userdata = [
    {
      name: "Jan",
      "Active User": 4000,
    },
    {
      name: "Feb",
      "Active User": 3000,
    },
    {
      name: " Mar",
      "Active User": 2000,
    },
    {
      name: " Apr",
      "Active User": 2780,
    },
    {
      name: "May",
      "Active User": 1890,
    },
    {
      name: " Jun",
      "Active User": 2390,
    },
    {
      name: " Jul",
      "Active User": 3490,
    },
    {
      name: " Agu",
      "Active User": 3290,
    },
  ];

  
  export const rows = [
    {
      id: 1,
      lastName: "wood",
      avatar: "./2.jpg",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
      firstName: "Jon",
      age: 35,
    },
    {
      id: 2,
      lastName: "wood",
      avatar: "./2.jpg",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
      firstName: "jon",
      age: 35,
    },
    {
      id: 3,
      lastName: "wood",
      avatar: "./2.jpg",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
      firstName: "Jon",
      age: 35,
    },
    {
      id: 4,
      lastName: "wood",
      avatar: "./2.jpg",
      email: "     jon@gmail.com",
      status: "active",
      transaction: "$120.00",
      firstName: "Jon",
      age: 35,
    },
    {
      id: 5,
      lastName: "wood",
      avatar: "./2.jpg",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
      firstName: "Jon",
      age: 35,
    },
    {
      id: 6,
      lastName: "wood",
      avatar: "./2.jpg",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
      firstName: "Jon",
      age: 35,
    },
    {
      id: 7,
      lastName: "wood",
      avatar: "./2.jpg",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
      firstName: "Jon",
      age: 35,
    },
    {
      id: 8,
      lastName: "wood",
      avatar: "./2.jpg",
      email: "jon@gmail.com",
      status: "active",
      transaction: "$120.00",
      firstName: "Jon",
      age: 35,
    },
  ];
  export const products = [
    {
      id: 1,
      name:"Apple Airpods",
      
      img: "./6.webp",
      stock: 188,
      status: "active",
      price: "$153.00",
      
  
    },
    {
      id: 2,
      name:"Apple Airpods",
      
      img: "./6.webp",
      stock: 188,
      status: "active",
      price: "$153.00",
      
  
    },
    {
      id: 3,
      name:"Apple Airpods",
      
      img: "./6.webp",
      stock: 188,
      status: "active",
      price: "$153.00",
      
  
    },
    {
      id: 4,
      name:"Apple Airpods",
      img: "./6.webp",
      stock: 188,
      status: "active",
      price: "$153.00",
      
  
    },
    {
      id: 5,
      name:"Apple Airpods",
      
      img: "./6.webp",
      stock: 188,
      status: "active",
      price: "$153.00",
      
  
    },
    {
      id: 6,
      name:"Apple Airpods",
      img:  "./6.webp",
      stock: 188,
      status: "active",
      price: "$153.00",
      
  
    },
    {
      id: 7,
      name:"Apple Airpods",
      
      img: "./6.webp",
      stock: 188,
      status: "active",
      price: "$153.00",
      
  
    },
    {
      id: 8,
      name:"Apple Airpods",
      
      img: "./6.webp",
      stock: 188,
      status: "active",
      price: "$153.00",
      
  
    },
  ];
  export const productdata = [
    {
      name: "Jan",
      "Sales": 4500,
    },
    {
      name: "Feb",
      "Sales": 2540,
    },
    {
      name: " Mar",
      "Sales": 3300,
    },
    {
      name: " Apr",
      "Sales": 3800,
    },
    
  ];
