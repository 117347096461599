import './style.css'
import * as React from "react";

import TopBar from "./components/TopBar/TopBar";
import SideBar from './components/sidebar/SideBar';
import Home from './pages/home/Home';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom";
import UserList from './pages/userList/UserList';
import Update from './pages/Update/Update';
import NewUser from './pages/newUser/NewUser';
import Products from './pages/products/Products';
import UpdateProduct from './pages/updateProduct/UpdateProduct';
import NewProduct from './pages/newProduct/NewProduct';
import FeedbackForm from './pages/notifactions/Notifactions';



function App() {
  const LayOut =()=>{

  return (
<div>
  
<TopBar/>

<div className='container'>
<SideBar/>


<div className='outlet'>

<Outlet/>

</div>
</div>




</div>


  );
}
const router = createBrowserRouter([
  {
    path: "/",
    element: <LayOut />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path:"/users",
        element:<UserList/>
      },
      {
        path:"/users/:userId",
        element:<Update/>
      },
      {
        path:"/newuser",
        element:<NewUser/>
      },
      {
        path:"/products",
        element:<Products/>
      },
      {
        path:"/product/:productId",
        element:<UpdateProduct/>
      },
      {
        path:"/newProduct",
        element:<NewProduct/>
      },
      {
        path:"/noifactions",
        element:<FeedbackForm/>
      },
      {
        path:"/newProduct",
        element:<NewProduct/>
      },
      
 
   
    ],
  },
 
]); 
return <RouterProvider router={router} />;

 }

export default App;
