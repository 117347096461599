import { Publish } from "@mui/icons-material";
import "./NewProduct";

export default function NewProduct() {
  return (
    <div className="nerproduct">
      <form className="newproductForm">
        <div className="newUseerItem">
          <h1>New Prodcut</h1>
          <label htmlFor="">Product Name</label>
          <input type="text" placeholder="Apple Airpods" />
        </div>

        <div className="newUseerItem">
          <label> Stock</label>
          <input type="text" placeholder="123" />
        </div>

        <div className="newUseerItem">
          <label>Active</label>
          <select className="newUserSelect" name="active" id="active">
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>

        <div className="newUseerItem">
          <label htmlFor="file">Image</label>
          <Publish />
          <input type="file" name="file" style={{ display: "none" }} />
        </div>
      </form>
      <button className="newUserBtn">Create</button>
    </div>
  );
}
