import "./TopBar.css";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import SettingsIcon from "@mui/icons-material/Settings";
export default function TopBar() {
  return (
    <div className="topbar">
      <div className="topbarwrapper">
        <div className="topleft">
          <span className="logo">Mo Admin</span>
        </div>
        <div className="topright">
          <div className="topbariconcontainer">
            <NotificationsNoneOutlinedIcon />
            <span className="topiconbar">2</span>
          </div>
          <div className="topbariconcontainer">
            <LanguageIcon />
            <span className="topiconbar">2</span>
          </div>
          <div className="topbariconcontainer">
            <SettingsIcon />
          </div>
          <img className="topavatar" src="./5.webp" alt="" />
        </div>
      </div>
    </div>
  );
}
